import { call, put, takeEvery } from "redux-saga/effects";

import { GET_USER, SET_USER } from "../actions/index";
import notariaService from "../../services/index";

function* getAdminCampaignsSaga(action: any): any {
  try {
    const response: any = yield call(
      notariaService.loginUser,
      action.payload.email,
      action.payload.password
    );
    yield put(SET_USER(response));
  } catch (err) {
    yield put(SET_USER({ error: err }));
  }
}

export default function* notariaSaga() {
  yield takeEvery(GET_USER, getAdminCampaignsSaga);
}
