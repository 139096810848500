import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";
import { Provider } from "react-redux";
import store from "./store/index";
import { SW_INIT, SW_UPDATE } from "./store/actions";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#485c47" }, // Purple and green play nicely together.
      secondary: { main: "#d2ae65" }, // This is just green.A700 as hex.
    },
  },
  esES
);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => {
    store.dispatch({ type: SW_INIT });
  },
  onUpdate: (reg) => {
    store.dispatch({ type: SW_UPDATE, payload: reg });
  },
});
