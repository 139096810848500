import React, { useState, useEffect, useContext } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import routes from "./routes";
import firebaseConfig from "./firebase.config";
import * as firebase from "firebase";
import { protectedRoutesAdmin, protectedRoutes } from "./protectedRoutes";
import NotariaService from "./services/index";
import { SET_USER, SW_INIT } from "./store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import Alert from "./components/common/Alert";
import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SnackbarProvider } from "notistack";
import { NoService } from "./components/no-service/NoService";

export const queryClient = new QueryClient();

firebase.initializeApp(firebaseConfig);

export const AuthContext = React.createContext({} as Props);

interface Props {
  isLoggedIn: boolean;
  setLoggedIn: Function;
}

function App() {
  const dispatch = useDispatch();
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [sessionChecked, setSessionChecked] = useState<boolean>(false);

  const isServiceWorkerInitialized = useSelector(
    (state: any) => state.servicesSelected.serviceWorkerInitialized
  );
  const isServiceWorkerUpdated = useSelector(
    (state: any) => state.servicesSelected.serviceWorkerUpdated
  );
  const serviceWorkerRegistration = useSelector(
    (state: any) => state.servicesSelected.serviceWorkerRegistration
  );

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e: any) => {
        if (e.target.state === "activated") {
          //window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(SET_USER(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  function readSession() {
    const user: any = window.sessionStorage.getItem(
      `firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`
    );

    if (user) {
      //get user information
      NotariaService.getUserInformation()
        .then((response: any) => {
          dispatch(SET_USER(response));

          setLoggedIn(true);

          /* messaging.requestPermission().then(function(){
            console.log('have permission')
            return messaging.getToken();
          }).then((token)=>{
            console.log(token)
          })
          
          .catch(function(error){
            console.log(error)
            console.log('error occurred')
          })*/
        })
        .finally(() => {
          setSessionChecked(true);
        });
    } else {
      setSessionChecked(true);
    }
  }
  useEffect(() => {
    readSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setLoggedIn } as Props}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3}>
          <div className="App-alert">
            {isServiceWorkerInitialized ? (
              <Alert
                text="Service Worker is initialized for the first time"
                type={SW_INIT}
              />
            ) : null}
            {isServiceWorkerUpdated && updateServiceWorker()}
          </div>
          <ReactQueryDevtools initialIsOpen={false} />
          {sessionChecked ? <Shell /> : null}
        </SnackbarProvider>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}

export default App;

const Shell = () => {
  const Auth = useContext(AuthContext);
  const user = useSelector((state: any) => {
    return state.servicesSelected.user;
  });
  return (
    <Router>
      <Routes>
        <Route path="*" element={<NoService />} />
      </Routes>
    </Router>
  );
};
