import { serviceAction } from "../slices/index";

export const {
  SERVICE_DATA,
  SERVICE_SELECT,
  SET_ACTIVE_STEP,
  GET_USER,
  SET_USER,
  SW_INIT,
  SW_UPDATE,
} = serviceAction;
