import * as firebase from "firebase";
import Axios from "axios";
import IWeddingEvent from "../core/interfaces/IWeddingEvent";
import { user } from "../models";
const notariaService = {
  loginUser,
  registerUser,
  getUserHistory,
  recoverPassword,
  getUserInformation,
  getHistory,
  getUserById,
  updateStatus,
  saveUserToken,
  sendPushWebNotification,
  createWeddingEvent,
  updateWeddingEvent,
  getAllWeddingEvents,
  updateUserData,
};

function getUserInformation() {
  const db = firebase.firestore();
  let userPromise: any = new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        db.collection("users")
          .where("uid", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              const user = querySnapshot.docs[0];
              // rest of your code
              resolve(user.data());
            } else {
              reject("error");
            }
          });
      } else {
        reject("error");
      }
    });
  });
  return userPromise;
}

function getUserById(userId: string) {
  const db = firebase.firestore();
  let userPromise: any = new Promise((resolve, reject) => {
    db.collection("users")
      .where("uid", "==", userId)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const user = querySnapshot.docs[0];
          // rest of your code
          resolve(user.data());
        } else {
          reject("error");
        }
      })
      .catch(() => {
        reject();
      });
  });
  return userPromise;
}

function loginUser(email: string, password: string) {
  const db = firebase.firestore();
  let loginPromise: any = new Promise((resolve, reject) => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((res) => {
            if (res.user) {
              //get the information of the user to obtain the rol
              console.log(res.user.uid);
              //	resolve(res.user)

              db.collection("users")
                .where("uid", "==", res.user.uid)
                .get()
                .then((querySnapshot) => {
                  if (!querySnapshot.empty) {
                    const user = querySnapshot.docs[0];
                    // rest of your code
                    resolve(user.data());
                  } else {
                    reject("error");
                  }
                });
            } else {
              reject("error");
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
  });
  return loginPromise;
}

function registerUser(
  email: string,
  password: string,
  username: any,
  cellphone: string,
  document: any
) {
  const db = firebase.firestore();
  let createUser: any = new Promise((resolve, reject) => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then((res: any) => {
            console.log(res.user.uid);

            db.collection("users")
              .add({
                email: email,
                name: username.firstName,
                lastName: username.lastName,
                cellphone: cellphone,
                type: document.documentType,
                document: document.documentNumber,
                uid: res.user.uid,
              })
              .then((response) => {
                resolve(res);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
  });
  return createUser;
}

function recoverPassword(email: string) {
  var actionCodeSettings = {
    url: "http://localhost:3000",
    CodeInApp: true,
  };
  let recoverPassword: any = new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(function (res: any) {
        resolve(res);
      })
      .catch(function (e: any) {
        // Error occurred. Inspect error.code.
        reject(e);
      });
  });
  return recoverPassword;
}

/*let t = querySnapshot.docs[querySnapshot.docs.length-1];
  db.collection("services")
  .orderBy("date","desc")
  .limit(100)
  .startAfter(t)
    .get()
    
    .then((querySnapshot:firebase.firestore.QuerySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc: any) => {
        //look for the user if is on cache skip and put the value
        console.log(doc.data());
      });
    });*/

function getHistory(
  type?: string,
  status: Array<string> = [
    "CANCELADA",
    "EN PROCESO",
    "creada",
    "PENDIENTE DE PAGO",
    "TERMINADA",
  ]
) {
  let historyOfUser: any = new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    const servideDocs = db.collection("services");
    if (type) {
      if (status) {
        let queryOptions: any = [];
        status.forEach((status: string) => {
          let typeQuery;
          if (type !== "ALL") {
            typeQuery = servideDocs.where("type", "==", type);
            typeQuery = typeQuery.where("status", "==", status);
          } else {
            typeQuery = servideDocs.where("status", "==", status);
          }
          queryOptions.push(typeQuery.get());
        });
        const results = await Promise.all(queryOptions);
        let t: any = [];
        results.forEach((res: any) => {
          t = t.concat(res.docs);
        });

        resolve(t);
      } else {
        let petitions = servideDocs.where("type", "==", type);
        const [typeQuerySnapshot] = await Promise.all([petitions.get()]);
        resolve(typeQuerySnapshot);
      }
    } else {
      let typeQuery = servideDocs.orderBy("date", "desc").get();
      const [typeQuerySnapshot] = await Promise.all([typeQuery]);
      resolve(typeQuerySnapshot);
    }
  });
  return historyOfUser;
}

function updateStatus(status: string, id: string) {
  let updateService: any = new Promise<void>((resolve, reject) => {
    const db = firebase.firestore();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection("services")
          .doc(id)
          .update({
            status: status,
            UpdatedBy: user.uid,
          })
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      } else {
        reject();
      }
    });
  });
  return updateService;
}

function getUserHistory() {
  let historyOfUser: any = new Promise((resolve, reject) => {
    const db = firebase.firestore();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection("services")
          .where("user", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            resolve(querySnapshot);
          });
      } else {
        reject();
      }
    });
  });
  return historyOfUser;
}

//Save the token for notifications
function saveUserToken(uid: string, token: string) {
  const db = firebase.firestore();
  let addToken: any = new Promise((resolve, reject) => {
    db.collection("usersToken")
      .add({
        token: token,
        uid: uid,
      })
      .then((response) => {
        resolve(response);
      });
  });
  return addToken;
}

//Send push notification when someone create a  process

//67Igx5u7CohXYNNW4sW2fA7mMcv1
//wxDX9GpIENVQD30pz8jOPMj4YRM2 --registro civil

// sWzqT7bxUdZSX6NqlB51jjdr2F52 -- protocolo
async function sendPushWebNotification(uid: string) {
  const db = firebase.firestore();
  const querySnapshot = await db
    .collection("usersToken")
    .where("uid", "==", uid)
    .get();

  if (!querySnapshot.empty) {
    //const user = querySnapshot.docs[0];
    querySnapshot.docs.forEach((doc) => {
      Axios.post(
        "https://fcm.googleapis.com/fcm/send",
        {
          notification: {
            title: "Nueva solicitud enviada",
            body: "Se creo una nueva solicitud en la plataforma notaria 13",
            click_action: "http://app.notaria13medellin.co/",
            icon:
              "https://app.notaria13medellin.co/static/media/logo-shield.7ed22ada.svg",
          },
          to: doc.data().token,
        },
        {
          headers: {
            Authorization:
              "key=AAAARyfvu7U:APA91bEE3eezyWOtz-PIq11zEsVBak_ClysHdvGtAHz6LdmbkFrU1T1-QC0S0iaAT2slbUOk9TjmpLgPa3q5JKBNfsP8eDcd1HKNwPvwPH1kEEAKZbqdP2zdYnJ4XFIf90cyZNLwJSQU",
            "content-type": "application/json",
          },
        }
      );
    });
  } else {
    //reject("error");
  }
}

async function createWeddingEvent(weddingEvent: IWeddingEvent) {
  const db = firebase.firestore();
  let id: string | undefined;
  const user = firebase.auth().currentUser;
  if (user) {
    // User logged in already or has just logged in.
    try {
      const res = await db.collection("marriages").add(weddingEvent);
      console.log("ID", res.id);
      id = res.id;
    } catch (error) {
      console.error("error");
    }
  }
  /*  }
  await firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      // User logged in already or has just logged in.
      try {
        const res = await db.collection("marriages").add(weddingEvent);
        console.log('ID', res.id)
        id = res.id;
      } catch (error) {
        console.error("error");
      }
    } else {
      // User not logged in or has just logged out.
      return undefined;
    }
  });
  console.log("the id",id) */
  return id;
}

async function updateWeddingEvent(weddingEvent: IWeddingEvent) {
  const db = firebase.firestore();
  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      // User logged in already or has just logged in.
      try {
        const res = await db
          .collection("marriages")
          .doc(weddingEvent.uid)
          .update({
            info: weddingEvent.info,
            comments: weddingEvent.comments,
            status: weddingEvent.status,
            UpdatedBy: user.uid,
          });

        return res;
      } catch (error) {
        console.log("error");
      }
    } else {
      // User not logged in or has just logged out.
    }
  });
}

async function getAllWeddingEvents() {
  /*
  let historyOfUser: any = new Promise((resolve, reject) => {
    const db = firebase.firestore();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection("services")
          .where("user", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            resolve(querySnapshot);
          });
      } else {
        reject();
      }
    });
  });
  return historyOfUser;

*/

  let events: any = new Promise((resolve, reject) => {
    const db = firebase.firestore();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // User logged in already or has just logged in.
        try {
          const querySnapshot = await db.collection("marriages").get();
          resolve(querySnapshot);
        } catch (error) {
          reject(error);
          console.log("error");
        }
      } else {
        // User not logged in or has just logged out.
      }
    });
  });
  return events;
}
//Update basic contact info
function updateUserData(userData: user) {
  let updateService: Promise<void> = new Promise<void>((resolve, reject) => {
    const db = firebase.firestore();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .where("uid", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                // Step 2: Merge new data with existing data and update the document
                const docRef = doc.ref;
                const newData = userData;
                docRef
                  .set(newData, { merge: true })
                  .then(() => {
                    console.log("Document successfully updated!");
                    resolve();
                  })
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                    reject();
                  });
              });
            } else {
              reject();
            }
          });
      } else {
        reject();
      }
    });
  });
  return updateService;
}

export default notariaService;
