import { Box, Container, CssBaseline } from "@material-ui/core";
import React from "react";
import { FC } from "react";
import Footer from "../common/Footer";
import { Height } from "@material-ui/icons";

interface NoServiceProps {}

export const NoService: FC<NoServiceProps> = () => {
  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{
        height: "100vh",
        alignContent: "center",
        fontSize: "18px",
        textAlign: "justify",
      }}
    >
      <CssBaseline />
      <div style={{ display: "flex", height: "100" }}>
        A partir del 15 de febrero, la Notaría 13 cambiará de notario. Por lo
        tanto, si tiene algún trámite abierto o pendiente, le solicitamos
        comunicarse telefónicamente o de manera presencial para recibir
        asistencia.
        <br />
        <br />
        Agradecemos su comprensión y lamentamos las molestias que esto pueda
        ocasionar.
      </div>
      <Box mt={5}>
        <Footer />
      </Box>
    </Container>
  );
};
